import React, { useRef } from "react";
import { useState } from "react";
import WorksDesc from "../WorksDesc/WorksDesc";
import ImageSlider from "../ImagesSlider/ImageSlider";
import widthScreen from "../utils/widthScreen/widthScreen";
import CarouselDesktop from "../CarouselDesktop/CarouselDesktop";
import CarouselTabMob from "../CarouselTabMob/CarouselTabMob";
import scrollScreen from "../utils/scrollScreen/scrollScreen";

const Porfolio = () => {
  const [isHovered, setIsHovered] = useState(false);
  const width = widthScreen();
  const [isAnimated, setIsAnimated] = useState(false);
  const elementToAnimateRef = useRef(null);

  scrollScreen(isAnimated, setIsAnimated, elementToAnimateRef);

  return (
    <div id="portfolio">
      <h1 className="sub-title">Projets</h1>
      <div className="containerPorfolio">
        {width > 992 && ( // si true width > 992 && alors  envoie le div l'opérateur logique && est souvent utilisé pour conditionner l'affichage d'éléments JSX sans spécifier explicitement une valeur alternative lorsqu'une condition est fausse.
          <div
            ref={elementToAnimateRef}
            className={isAnimated ? "containerImagesSliderDesk" : "WorkHidden"}
          >
            {/* // on affiche les images des projets en mode desktop  dans la div portfolio */}
            {isHovered && (
              <>
                <ImageSlider images={isHovered.images} />
                <div className="containe_descWork">
                  <div className="layer">
                    <h2>{isHovered.title}</h2>
                    <p>{isHovered.description}</p>
                    <div className="containerIcon">{isHovered.icon}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        <div className="containe_carousel">
          {/* pour le desktop ------------------------------------------------ */}
          {width > 992 ? (
            <CarouselDesktop
              WorksDesc={WorksDesc}
              setIsHovered={setIsHovered}
            />
          ) : (
            // pour Tablette et Mobile------------------------------------
            <CarouselTabMob WorksDesc={WorksDesc} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Porfolio;
