import { Link as ScrollLink } from "react-scroll";

import { useState } from "react";
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="containerNavBar">
      <nav className="navBar">
        <ul>
          <li>
            <ScrollLink
              data-text="Accueil"
              to="app"
              smooth="easeInOutQuart"
              duration={1000}
            >
              Accueil
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              data-text="A_propos_de_moi"
              to="about"
              smooth="easeInOutQuart"
              duration={1000}
            >
              A<span>_</span>propos<span>_</span>de<span>_</span>moi
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              data-text="Projets"
              to="portfolio"
              smooth="easeInOutQuart"
              duration={1000}
            >
              Projets
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              data-text="Contact"
              to="contact"
              smooth="easeInOutQuart"
              duration={1000}
            >
              Contact
            </ScrollLink>
          </li>
        </ul>
      </nav>

      <div onClick={toggle} className={`toggle ${isOpen ? "open" : ""}`}>
        <span></span>
      </div>

      <nav className={`navBarMobile ${isOpen ? "open" : ""}  `}>
        <ul>
          <li className={`${isOpen ? "open" : ""}`} style={{ "--a": 0 }}>
            <ScrollLink to="app" smooth="easeInOutQuart" duration={1000}>
              Accueil
            </ScrollLink>
          </li>
          <li className={`${isOpen ? "open" : ""}`} style={{ "--a": 1 }}>
            <ScrollLink to="about" smooth="easeInOutQuart" duration={1000}>
              A propos de moi
            </ScrollLink>
          </li>

          <li className={`${isOpen ? "open" : ""}`} style={{ "--a": 3 }}>
            <ScrollLink to="portfolio" smooth="easeInOutQuart" duration={1000}>
              Projets
            </ScrollLink>
          </li>
          <li className={`${isOpen ? "open" : ""}`} style={{ "--a": 4 }}>
            <ScrollLink
              data-text="Contact"
              to="contact"
              smooth="easeInOutQuart"
              duration={1000}
            >
              Contact
            </ScrollLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
