import Cursor from "./Components/Cursor/Cursor";

import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "./Footer/Footer";
function App() {
  return (
    <>
      <Cursor />
      <div id="app">
        <Header />
        <Main />
        <Footer />
      </div>
    </>
  );
}

export default App;
