import { useState, useEffect } from "react";

const AnimatedText = ({ textArray, letterDelay = 40 }) => {
  const [letterIndex, setLetterIndex] = useState(0);

  useEffect(() => {
    const id = setTimeout(() => {
      if (letterIndex < textArray[0].length) {
        setLetterIndex((prevIndex) => prevIndex + 1);
      }
    }, letterDelay);

    return () => clearTimeout(id);
  }, [letterIndex, letterDelay, textArray]);

  return (
    <p className="paraDesc">
      {textArray[0].slice(0, letterIndex)}
      <span>|</span>
    </p>
  );
};

export default AnimatedText;
