function AnimationTechnoAbout() {
  return (
    <div className="about-col-1">
      <div className=" containerComp">
        <div className="boxComp">
          <span style={{ "--s": 1 }}>
            <i>HTML</i>
          </span>
          <span style={{ "--s": 2 }}>
            <i>CSS</i>
          </span>
          <span style={{ "--s": 3 }}>
            <i>JAVASCRIPT</i>
          </span>
          <span style={{ "--s": 4 }}>
            <i>SASS</i>
          </span>
          <span style={{ "--s": 5 }}>
            <i>GITHUB</i>
          </span>
          <span style={{ "--s": 6 }}>
            <i>REACT.JS</i>
          </span>
          <span style={{ "--s": 7 }}>
            <i>REDUX</i>
          </span>
          <span style={{ "--s": 8 }}>
            <i>SEO</i>
          </span>
          <span style={{ "--s": 9 }}>
            <i>NODE.JS</i>
          </span>
          <span style={{ "--s": 10 }}>
            <i>MongoDB</i>
          </span>
          <span style={{ "--s": 11 }}>
            <i>VS Code</i>
          </span>
          <span style={{ "--s": 12 }}>
            <i>Wordpress</i>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AnimationTechnoAbout;
