import React, { useState, useEffect } from "react";

function ImageSlider(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = props.images.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % length);
    }, 2000);
    return () => clearInterval(interval);
  }, [currentIndex, length]);

  return (
    <>
      <img
        src={props.images[currentIndex]}
        alt="slider"
        className="imagesSlider"
      />
    </>
  );
}

export default ImageSlider;
