import AnimatedText from "../AnimationLetterAbout/AnimationLetterAbout";
import AnimationTechnoAbout from "../AnimationTechnoAbout/AnimationTechnoAbout";

const About = (props) => {
  return (
    // <!-- ----------------------------about-------------------- -->
    <div id="about">
      <div className="row">
        <AnimationTechnoAbout />
        <div className="about-col-2">
          <h1 className=" sub-title">A propos de moi</h1>

          <div className="boxContent">
            <div className="glass"></div>

            <div id="containeTextAbout">
              <AnimatedText
                textArray={[
                  " Passionné par l'informatique j'ai décidé de me reconvertir dans ce domaine après quelques années dans le secteur de la logistique. Plus précisément, je souhaite me spécialiser dans le développement web front-end et par la suite devenir un développeur full-stack ",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
