// import { useEffect } from "react";

const scrollScreen = (isAnimated, setIsAnimated, elementToAnimateRef) => {

    const handleScroll = () => {
      if (elementToAnimateRef.current) {
        const elementPosition =
          elementToAnimateRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
  
        if (elementPosition < windowHeight) {
          setIsAnimated(true);
  
        } else {
          setIsAnimated(false);
        }
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  };
 



   scrollScreen();

export default scrollScreen;
