import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import scrollScreen from "../utils/scrollScreen/scrollScreen";

const CarouselDesktop = ({ WorksDesc, setIsHovered }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const elementToAnimateRef = useRef(null);
  scrollScreen(isAnimated, setIsAnimated, elementToAnimateRef);
  
  return (
    <div
      ref={elementToAnimateRef}
      className={isAnimated ? "work_list_carousel" : "WorkHidden"}
    >
      {WorksDesc.map((work) => (
        <div
          className="work "
          style={{ "--a": work.order }}
          onMouseEnter={() =>
            setIsHovered({
              description: work.description,
              title: work.title,
              icon: work.iconType.map((icon, index) => (
                <img
                  src={icon}
                  alt={`icon-${index}`}
                  key={`icon-${index}`}
                  className="img-icon"
                />
              )),

              images: work.image, //pour le background portfolio qui defile au hover
            })
          }
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* on obtient juste la 1ere image pour la couverture ainsi que le lien GitHub Pages    */}
          <div className="containeImgCover">
            <Link to={work.lien} target={"_blank"}>
              <img src={work.image[0]} alt={`work-${work.order}`} />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CarouselDesktop;
