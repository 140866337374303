import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSquareGithub } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faSquarePhoneFlip } from "@fortawesome/free-solid-svg-icons";
import Cv from "../img/cv.pdf";
import BtnSubmitDesk from "../Components/utils/BtnSubmitDesk/BtnSubmitDesk";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import BtnSubmitMobile from "../Components/utils/BtnSubmitMobile/BtnSubmitMobile";
import widthScreen from "../Components/utils/widthScreen/widthScreen";
import scrollScreen from "../Components/utils/scrollScreen/scrollScreen";

const Contact = () => {
  const formRef = useRef();
  const confirmFormRef = useRef();
  const inputAnimation = useRef();
  const width = widthScreen();
  const [isAnimated, setIsAnimated] = useState(false);
  const elementToAnimateRef = useRef(null);

  scrollScreen(isAnimated, setIsAnimated, elementToAnimateRef);

  const InputAnimation = (e) => {
    if (e.target.value !== "") {
      e.target.parentNode.classList.add("animationLabel");
    } else if (e.target.value === "") {
      e.target.parentNode.classList.remove("animationLabel");
    }
  };

  // on gère les mails avec send Email
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_PUBLIC_KEY
      )

      .then(
        (result) => {
          console.log(result.text);
          setTimeout(() => {
            confirmFormRef.current.style.display = "flex";
          }, 2000);
          e.target.reset();
          setTimeout(() => {
            confirmFormRef.current.style.display = "none";
          }, 8000);
        },

        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div id="contact">
      {width > 992 ? (
        <div className="row">
          <div className="contact-left">
            <h1 className="sub-title">Contact</h1>
            <div className="container-icons">
              <p>
                <i>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </i>
                contact@faouaz.com
              </p>
              <p>
                <i>
                  <FontAwesomeIcon icon={faSquarePhoneFlip} />
                </i>
                0619335681
              </p>
              <div className="social-icons">
                <Link
                  target="_blank"
                  to={"https://www.linkedin.com/in/abdallah-faouaz-156949276 "}
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
                <Link target="_blank" to={"https://github.com/GitHubFaouaz"}>
                  <FontAwesomeIcon icon={faSquareGithub} />
                </Link>
              </div>

              <Link to={Cv} target="_blank">
                <button className="btnCv">C V</button>
              </Link>
            </div>
          </div>

          <div className="contact-right">
            <form action="" onSubmit={sendEmail} ref={formRef}>
              <div className="formGroup">
                <label htmlFor="Name">Nom</label>
                <input
                  type="text"
                  name="Name"
                  required
                  id="Name"
                  ref={inputAnimation}
                  onInput={InputAnimation}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="Email">Mail</label>
                <input
                  type="email"
                  name="Email"
                  required
                  id="Email"
                  ref={inputAnimation}
                  onInput={InputAnimation}
                />
              </div>
              <div className="formGroup">
                <textarea
                  name="Message"
                  id="Message"
                  cols="0"
                  rows="6"
                ></textarea>
              </div>
              <div className="container-btn-span">
                <button type="submit">
                  <BtnSubmitDesk />
                </button>
                <span ref={confirmFormRef} className="confirmForm">
                  Message envoyé avec succès
                </span>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div
          className={isAnimated ? "row" : "FormHidden"}
          ref={elementToAnimateRef}
        >
          <div className="contact-left">
            <h1 className="sub-title">Contact</h1>
            <div className="container-icons">
              <p>
                <i>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </i>
                contact@faouaz.com
              </p>
              <p>
                <i>
                  <FontAwesomeIcon icon={faSquarePhoneFlip} />
                </i>
                0619335681
              </p>
              <div className="social-icons">
                <Link
                  target="_blank"
                  to={"https://www.linkedin.com/in/abdallah-faouaz-156949276 "}
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
                <Link target="_blank" to={"https://github.com/GitHubFaouaz"}>
                  <FontAwesomeIcon icon={faSquareGithub} />
                </Link>
              </div>

              <Link to={Cv} target="_blank">
                <button className="btnCv">C V</button>
              </Link>
            </div>
          </div>

          <div className="contact-right">
            <form action="" onSubmit={sendEmail} ref={formRef}>
              <div className="formGroup">
                <label htmlFor="Name">Nom</label>
                <input
                  type="text"
                  name="Name"
                  required
                  id="Name"
                  ref={inputAnimation}
                  onInput={InputAnimation}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="Email">Mail</label>
                <input
                  type="email"
                  name="Email"
                  required
                  id="Email"
                  ref={inputAnimation}
                  onInput={InputAnimation}
                />
              </div>
              <div className="formGroup">
                <textarea
                  name="Message"
                  id="Message"
                  cols="0"
                  rows="6"
                ></textarea>
              </div>
              <div className="container-btn-span">
                <button type="submit">
                  <BtnSubmitMobile />}
                </button>
                <span ref={confirmFormRef} className="confirmForm">
                  Message envoyé avec succès
                </span>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
