import React, { useEffect, useState } from "react";
import { useRef } from "react";
import widthScreen from "../utils/widthScreen/widthScreen";

const Cursor = () => {
  const [cursorPositions, setCursorPositions] = useState({ x: 0, y: 0 });
  const containeCursor = useRef(null);
  const width = widthScreen();

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPositions({ x: e.pageX, y: e.pageY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const cursorElements = (
    <>
      <div
        className="cursor-outer"
        style={{ top: cursorPositions.y, left: cursorPositions.x }}
      ></div>
      <div
        className="cursor-outer1"
        style={{ top: cursorPositions.y, left: cursorPositions.x }}
      ></div>
      <div
        className="cursor-outer2"
        style={{ top: cursorPositions.y, left: cursorPositions.x }}
      ></div>
      <div
        className="cursor-outer3"
        style={{ top: cursorPositions.y, left: cursorPositions.x }}
      ></div>
      <div
        className="cursor-inner"
        style={{ top: cursorPositions.y, left: cursorPositions.x }}
      ></div>
    </>
  );

  return (
    <>
      {width > 768 && (
        <div className="containeCursor" ref={containeCursor}>
          {cursorElements}
        </div>
      )}
    </>
  );
};

export default Cursor;
