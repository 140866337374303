import {
  projet2,
  projet3,
  projet4,
  projet5,
  projet6,
  projet7,
  projet8,
} from "../utils/ListeImages/ListeImages";

import html from "../../img/techno/Html.png";
import css from "../../img/techno/Css.png";
import js from "../../img/techno/Js.png";
import sass from "../../img/techno/Sass.png";
import react from "../../img/techno/React.png";
import nodeJs from "../../img/techno/nodejs.png";
import mongoDb from "../../img/techno/mongoDb.png";

const Works = [
  {
    order: 1,
    image: Object.values(projet2),
    title: "Projet 2 avec OpenClassRooms",
    description: "Integration d'une maquette avec HTML & CSS",
    iconType: [html, css],
    lien: "https://githubfaouaz.github.io/Reservia/",
  },
  {
    order: 2,
    image: Object.values(projet3),
    title: "Projet 3 avec OpenClassRooms",
    description: "Integration et animation d'une maquette avec HTML & SASS ",
    iconType: [html, css, sass],
    lien: "https://githubfaouaz.github.io/Ohmyfood/",
  },
  {
    order: 3,
    image: Object.values(projet4),
    title: "Projet 4 avec OpenClassRooms",
    description: "Optimisation d'un site web existant et SEO",
    iconType: [html, css],
    lien: "https://githubfaouaz.github.io/ChouetteAgence/",
  },
  {
    order: 4,
    image: Object.values(projet5),
    title: " Projet 5 avec OpenClassRooms",
    description: "Construiction d'un site e-commerce en JavaScript",
    iconType: [html, sass, js],
    lien: "https://github.com/GitHubFaouaz/Oricono",
  },
  {
    order: 5,
    image: Object.values(projet6),
    title: "Projet 6 avec OpenClassRooms",
    description:
      "Construction d'une API sécurisée pour une application d'avis gastronomiques",
    iconType: [nodeJs, mongoDb],
    lien: "https://github.com/GitHubFaouaz/SaucePiquante",
  },
  {
    order: 6,
    image: Object.values(projet7),
    title: " Projet 7 avec OpenClassRooms",
    description:
      "Création d'une application web de location immobilière avec React",
    iconType: [html, css, js, react],
    lien: "https://githubfaouaz.github.io/Kasa/",
  },
  {
    order: 7,
    image: Object.values(projet8),
    title: "toDoListsJs",
    description: "Création d'une application de planification de tâches ",
    iconType: [html, css, js],
    lien: "https://githubfaouaz.github.io/toDoListsJs/",
  },
];

export default Works;
