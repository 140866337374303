import AnimationLetterBanner from "../AnimationLetterBanner/AnimationLetterBanner";
import ImgProfil from "../ImgProfil/ImgProfil";

function Card() {
  return (
    <div className="banners">
      <div className="containerAnimLetter">
        <h1>
          <AnimationLetterBanner />
        </h1>

        <p>
          <i>D</i>
          <i>e</i>
          <i>v</i>
          <i>e</i>
          <i>l</i>
          <i>o</i>
          <i>p</i>
          <i>p</i>
          <i>e</i>
          <i>u</i>
          <i>r</i> <i>W</i>
          <i>e</i>
          <i>b</i>
        </p>
      </div>

      <div className="containerImgProfil">
        <ImgProfil />
      </div>
    </div>
  );
}

export default Card;
