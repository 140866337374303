import React, { useEffect, useState } from "react";

const AnimationLetterBanner = () => {
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    const word = ["Faouaz"];

    const newLetters = word[0].split("").map((char, index) => (
      <span
        key={index}
        className="animation "
        style={{ animationDelay: `${index / 5}s` }}
      >
        {char}
      </span>
    ));

    setLetters(newLetters);
  }, []);

  return <p className="containerLetterBanner"> {letters}</p>;
};
export default AnimationLetterBanner;
