import React from "react";

const BtnSubmitMobile = () => {
  return (
    <div className="container-btnMobile">
      <span>Soumettre</span>
      <span>Soumettre</span>
    </div>
  );
};

export default BtnSubmitMobile;
