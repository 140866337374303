export const projet2 = {
  //nous avons utilisé require() au lieu d'import pour importer les projets car import ne peut pas être utilisé dynamiquement avec des variables comme c'est le cas avec les propriétés d'objets
  Desk1: require("../../../img/projet2/desk1.webp"),
  Desk2: require("../../../img/projet2/desk2.webp"),
};

export const projet3 = {
  Desk1: require("../../../img/projet3/desk1.webp"),
  Desk2: require("../../../img/projet3/desk2.webp"),
  Desk3: require("../../../img/projet3/desk3.webp"),
  Desk4: require("../../../img/projet3/desk4.webp"),
};
export const projet4 = {
  Desk1: require("../../../img/projet4/desk1.webp"),
  Desk2: require("../../../img/projet4/desk2.webp"),
  Desk3: require("../../../img/projet4/desk3.webp"),
  Desk4: require("../../../img/projet4/desk4.webp"),
};
export const projet5 = {
  Desk1: require("../../../img/projet5/desk1.webp"),
  Desk2: require("../../../img/projet5/desk2.webp"),
  Desk3: require("../../../img/projet5/desk3.webp"),
  Desk4: require("../../../img/projet5/desk4.webp"),
};
export const projet6 = {
  Desk1: require("../../../img/projet6/desk1.webp"),
  Desk2: require("../../../img/projet6/desk2.webp"),
  Desk3: require("../../../img/projet6/desk3.webp"),
  Desk4: require("../../../img/projet6/desk4.webp"),
};
export const projet7 = {
  Desk1: require("../../../img/projet7/desk1.webp"),
  Desk2: require("../../../img/projet7/desk2.webp"),
  Desk3: require("../../../img/projet7/desk3.webp"),
  Desk4: require("../../../img/projet7/desk4.webp"),
  Desk5: require("../../../img/projet7/desk5.webp"),
};
export const projet8 = {
  Desk1: require("../../../img/projet8/desk.webp"),
};
