import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import ImageSlider from "../ImagesSlider/ImageSlider";
import widthScreen from "../utils/widthScreen/widthScreen";
import scrollScreen from "../utils/scrollScreen/scrollScreen";

const CarouselTabMob = ({ WorksDesc }) => {
  const width = widthScreen();
  const [isAnimated, setIsAnimated] = useState(false);
  const elementToAnimateRef = useRef(null);

  scrollScreen(isAnimated, setIsAnimated, elementToAnimateRef);
  
  return (
    <div
      ref={elementToAnimateRef}
      className={"work_list_carousel"}
    >
      {WorksDesc.map((work) => (
        <div
          className={isAnimated ? "work" : "WorkHidden"}
          style={{ "--a": work.order }}
        >
          <div className="containeImgCover">
            {/* on obtient juste la 1ere image pour la couverture    */}
            <img
              src={work.image[0]}
              alt={`work-${work.order}`}
              className="imgStatic"
            />
            {width > 768 && (
              <div className="containerImagesSliderTab">
                {work.image.length > 1 && <ImageSlider images={work.image} />}
              </div>
            )}
          </div>

          <div className="containe_descWork">
            <div className="layer">
              <h2>{work.title}</h2>
              <p>{work.description}</p>

              <div className="containerIcon">
                {work.iconType.map((icon, index) => (
                  <img
                    src={icon}
                    alt={`icon-${index}`}
                    key={`icon-${index}`}
                    className="img-icon"
                  />
                ))}
              </div>
            </div>
          </div>
          <Link to={work.lien} target="_blank">
            <div className="lienSite">voir le site</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CarouselTabMob;
