function TitlePortfolio() {
  return (
    <div className="titlePortfolio ">
      <p style={{ "--i": 1 }}>Portfolio</p>
      <p style={{ "--i": 2 }}>Portfolio</p>
      <p style={{ "--i": 3 }}>Portfolio</p>
    </div>
  );
}

export default TitlePortfolio;
