import React, { useMemo } from "react";
import { useEffect, useRef } from "react";

function JSAnimation() {
  const bannerRef = useRef(null);

  const blockRefs = useMemo(() => {
    return Array(140)
      .fill(0) // Remplit chaque élément du tableau avec la valeur 0
      .map((_, i) => React.createRef()); // Le _ est une convention pour indiquer que nous n'utilisons pas la valeur de l'élément (parce que nous ne nous soucions que de l'index i)Pour chaque index i, on crée un nouvel objet React.createRef().100 références  créées chacune à l'aide de React.createRef()
  }, []);

  useEffect(() => {
    for (let i = 0; i < 140; i++) {
      const duration = Math.random() * 5;
      blockRefs[i].current.style.animationDuration = `${duration}s`;
    }
  }, [blockRefs]);

  return (
    <div className="banner" ref={bannerRef}>
      {blockRefs.map((ref, i) => (
        <div key={i} className="blocks" ref={ref}></div>
      ))}
      <span>Soumettre</span>
    </div>
  );
}

export default JSAnimation;
