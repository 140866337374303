import React from "react";
import TitlePortfolio from "../Components/TitlePortfolio/TitlePortfolio";
import NavBar from "../Components/NavBar/NavBar";

const Header = () => {
  return (
    <div className="header">
      <TitlePortfolio />
      <NavBar />
    </div>
  );
};

export default Header;
