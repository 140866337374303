import React from "react";
import { useEffect, useRef, useMemo } from "react";

const ImgProfil = () => {
  const containerblockRef = useRef(null);

  const blockImgRefs = useMemo(() => {
    return Array(100)
      .fill(0) // Remplit chaque élément du tableau avec la valeur 0
      .map((_, i) => React.createRef()); // Le _ est une convention pour indiquer que nous n'utilisons pas la valeur de l'élément (parce que nous ne nous soucions que de l'index i)Pour chaque index i, on crée un nouvel objet React.createRef().100 références  créées chacune à l'aide de React.createRef()
  }, []);

  useEffect(() => {
    for (let i = 0; i < 100; i++) {
      const duration = Math.random() * 5;
      blockImgRefs[i].current.style.animationDuration = `${duration}s`;
      // La boucle dans useEffect vise à définir la propriété animationDuration de chaque élément référencé dans votre tableau blockImgRefs
    }
  }, [blockImgRefs]);

  return (
    <div className="container-block" ref={containerblockRef}>
      {blockImgRefs.map((ref, i) => (
        <div key={i} className="blockImg" ref={ref}></div>
      ))}
    </div>
  );
};

export default ImgProfil;
