import React from "react";
import About from "../Components/About/About";
import Banner from "../Components/Banner/Banner";
import Portfolio from "../Components/Portfolio/Portfolio";

const Main = () => {
  return (
    <div className="main">
      <Banner />
      <About />
      <Portfolio />
    </div>
  );
};

export default Main;
