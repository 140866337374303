import { useEffect } from "react";
import { useState } from "react";

const WidthScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);

  // useEffect pour ajouter un événement de redimensionnement de la fenêtre et mettre à jour width à chaque fois que la fenêtre est redimensionnée
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize); //addEventListener("resize"  permet d'écouter l'événement de redimensionnement de la fenêtre (resize) et d'autre click mouseover keydown load submit

    return () => window.removeEventListener("resize", handleResize); //on retourne une fonction qui supprime l'écouteur d'événement de redimensionnement enregistré précédemment
  }, [width]);

  return width;
};

//----------calcul largeur de l'ecran et choix du type de carousel(toggle)  au click
export default WidthScreen;
